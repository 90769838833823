import { Temporal } from "@js-temporal/polyfill";

import { Availability } from "src/storefront/types/catalog";

export const activeOrUpcomingAvailabilitiesToday = (availabilities: Availability[], time: Temporal.PlainTime, wday: number): Availability[] => {
  return availabilities.filter((av) => av.wday == wday && Temporal.PlainTime.compare(time, av.endTime) <= 0);
}

export const earliestStartingAvailability = (availabilities: Availability[]) => {
  return availabilities.sort((a, b) => Temporal.PlainTime.compare(a.startTime, b.startTime))[0];
}