export abstract class Copyable {
  copyWith(updates: { [prop in keyof this]?: this[prop] }): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...updates });
  }
}

interface ClassNameList {
  [className: string]: boolean;
}

export const classNames = (cns: ClassNameList): string => {
  return Object.entries(cns).reduce((accl, [className, enabled]) => {
    return enabled ? `${accl} ${className}` : accl;
  }, "");
};
